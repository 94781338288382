import React, { useMemo } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Api } from "../api/Api";
import { EntityType } from "../interfaces/EntityType";
import { FormStructure } from "../interfaces/FormStructure";
import { GAEvent, GAEventCategory } from "../interfaces/GAEvent";
import GoalType from "../interfaces/GoalType";
import { LinkType } from "../interfaces/LinkType";
import MedicationType from "../interfaces/MedicationType";
import { NavMenu, NavMenuItem } from "../interfaces/NavMenu";
import { ToolBoxDataType } from "../interfaces/ToolBoxData";
import {
  getCreateGoalFormStructure,
  getEditGoalFormStructure
} from "../Pages/Toolbox/Goal/GoalFormStructure";
import {
  getCreateMedicationFormStructure,
  getEditMedicationFormStructure
} from "../Pages/Toolbox/Medication/MedicationFormStructure";
import { updateContent, updateSection } from "../store/PageContentActions";
import { store } from "../store/Reducer";
import {
  addToToolBox,
  deleteFromToolBox,
  editToolBox,
  finishedLoading,
  loading
} from "../store/ToolBoxActions";

const mapDataToEntityFormat = (data: Object, dataType = ""): Object => {
  let keys = Object.keys(data);

  keys = keys.map((k) => {
    switch (dataType) {
      case ToolBoxDataType.GOALS: {
        if (k === "field_goal_status") {
          data[k] = data[k] ? "1" : "0";
        }
        return k;
      }
      case ToolBoxDataType.REMINDER: {
        switch (k) {
          case "frequency":
            return "field_tool_reminder_frequency";
          case "weekDay":
            return "field_tool_reminder_weekday";
        }
        return k;
      }
      default:
        return k;
    }
  });

  const arrayData = Object.values(data);
  keys = Object.keys(data);

  return arrayData.reduce((result: any, value: string, i: number) => {
    const currentKey = keys[i];
    const index = currentKey.includes("ref") ? "target_id" : "value";

    result[currentKey] = [{ [index]: value }];

    return result;
  }, {});
};

export const toEntityData = <T extends EntityType>(entity: T): Object => {
  const med = (entity as unknown) as MedicationType;
  const goal = (entity as unknown) as GoalType;

  if (med.frequency && med.startingDate && med.name) {
    return mapDataToEntityFormat({
      field_medication_label: med.name,
      field_medication_frequency: med.frequency,
      field_medication_starting_date: med.startingDate,
      field_medication_status: med.status,
      id: med.id,
    });
  }

  if (goal.date && goal.name && goal.status) {
    return mapDataToEntityFormat({
      field_goal_name: goal.name,
      field_goal_target_date: goal.date,
      field_goal_status: goal.status,
      id: goal.id,
    });
  }

  return {};
};

export const fromFieldEntityData = (data: any): Object => {
  if ("field_medication_label" in data) {
    return {
      name: data.field_medication_label,
      frequency: data.field_medication_frequency,
      startingDate: data.field_medication_starting_date,
      status: data.field_medication_status,
      id: data.id,
    };
  } else {
    return {
      name: data.field_goal_name,
      date: new Date(data.field_goal_target_date).toISOString(),
      status: data.field_goal_status,
      id: data.id,
    };
  }
};

export const fromEntityData = (data: any): Object => {
  if ("field_medication_label" in data) {
    return {
      name: data.field_medication_label[0].value,
      frequency: data.field_medication_frequency[0].value,
      startingDate: data.field_medication_starting_date[0].value,
      status: data.field_medication_status[0].value,
      id: data.id[0].value,
    };
  } else {
    return {
      name: data.field_goal_name[0].value,
      date: data.field_goal_target_date[0].value,
      status: data.field_goal_status[0].value,
      id: data.id[0].value,
    };
  }
};

export default mapDataToEntityFormat;

export const generateNav = (menu: NavMenu | NavMenuItem[]) => {
  if (!menu) {
    return <></>;
  }

  return Object.values(menu).map((menuItem, i) => {
    if (menuItem.url.slice(0, 1) === "/") {
      return (
        <div key={menuItem.label}>
          <RouterLink to={menuItem.url}>{menuItem.label}</RouterLink>
          {menuItem.child && generateNav(menuItem.child)}
        </div>
      );
    }

    return (
      <div>
        <a
          target="_blank"
          rel={"noreferrer"}
          href={menuItem.url}
          key={menuItem.label}
        >
          {menuItem.label}
        </a>
      </div>
    );
  });
};

export const generateFooterLinks = (menu: Array<LinkType>) => {
  if (!menu) {
    return <></>;
  }

  return Object.values(menu).map((menuItem, i) => {
    const newUri = menuItem.uri.replace("internal:", "");

    if (newUri.slice(0, 1) === "/") {
      return (
        <div key={menuItem.title}>
          <RouterLink to={newUri}>{menuItem.title}</RouterLink>
        </div>
      );
    }

    return (
      <div key={menuItem.title}>
        <a target="_blank" rel={"noreferrer"} href={newUri}>
          {menuItem.title}
        </a>
      </div>
    );
  });
};

export const changeLink = (htmlString: string) => {
  if (htmlString.includes("www")) {
    return htmlString.replace("www", "https://www");
  }

  if (!htmlString.includes(`href="/`)) {
    return htmlString.replace(`href="`, `href="/`);
  }

  return htmlString;
};

export const getVideoData = (data: any): Object => {
  if (typeof data.response === "undefined") {
    return {};
  }

  const response = JSON.parse(data.response);

  return {
    embedHTML: response?.embed?.html,
    pictures: response?.pictures,
  };
};

export const omitKey = (object, key) => {
  const clone = Object.assign({}, object);

  delete clone[key];

  return clone;
};

export const editToolBoxData = (
  modifyFn,
  pageType,
  entity,
  dataType: ToolBoxDataType,
  history,
  noRedirect = false,
  callback = () => {}
) => {
  store.dispatch(loading(pageType));
  modifyFn(mapDataToEntityFormat(entity, dataType)).then(
    (resp) => {
      if (dataType === ToolBoxDataType.MEDICATIONS) {
        createGAEvent(
          GAEvent.FORM_SUBMISSION,
          GAEventCategory.MEDICATION_MODIFIED
        );
      } else {
        createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.GOAL_MODIFIED);
      }

      store.dispatch(finishedLoading(pageType, resp.message, true));
      store.dispatch(editToolBox(fromFieldEntityData(entity), dataType));
      if (!noRedirect) {
        history.push("..");
      }

      callback();
    },
    (error) => {
      store.dispatch(finishedLoading(pageType, error.message, false));
    }
  );
};

export const deleteToolBoxData = (
  modifyFn,
  pageType,
  id,
  dataType: ToolBoxDataType,
  history,
  noRedirect = false,
  callback = () => {}
) => {
  store.dispatch(loading(pageType));
  modifyFn(id).then(
    (resp) => {
      if (dataType === ToolBoxDataType.MEDICATIONS) {
        createGAEvent(
          GAEvent.FORM_SUBMISSION,
          GAEventCategory.MEDICATION_DELETED
        );
      } else {
        createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.GOAL_DELETED);
      }

      store.dispatch(finishedLoading(pageType, resp.message, true));
      if (!noRedirect) {
        history.push("..");
      }
      store.dispatch(deleteFromToolBox(parseInt(id), dataType));
      callback();
    },
    (error) => {
      store.dispatch(finishedLoading(pageType, error.message, false));
    }
  );
};

export const createToolBoxData = (
  modifyFn,
  pageType,
  entity,
  dataType: ToolBoxDataType,
  history,
  parentUrl,
  callback = () => {}
) => {
  store.dispatch(loading(pageType));
  modifyFn(mapDataToEntityFormat(entity, dataType)).then(
    (resp) => {
      const entity = Object.values(resp.data.content)[0] as any;

      if (dataType === ToolBoxDataType.MEDICATIONS) {
        createGAEvent(
          GAEvent.FORM_SUBMISSION,
          GAEventCategory.MEDICATION_CREATED
        );
        entity.startingDate = UTCToLocalTimeString(
          new Date(entity.startingDate)
        );
      } else {
        createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.GOAL_CREATED);
        entity.date = UTCToLocalTimeString(new Date(entity.date));
      }

      store.dispatch(finishedLoading(pageType, resp.message, true));
      store.dispatch(addToToolBox(entity, dataType));
      history.push(parentUrl);
      callback();
    },
    (error) => {
      store.dispatch(finishedLoading(pageType, error.message, false));
    }
  );
};

export const findEntity = (id: number | string, dataType: ToolBoxDataType) => {
  return (store.getState().toolbox[dataType] as Array<any>).find(
    (d) => parseInt(d.id) === id
  );
};

export const getEditFormStructure = (
  data,
  dataType: ToolBoxDataType,
  content
): FormStructure => {
  return dataType === ToolBoxDataType.MEDICATIONS
    ? getEditMedicationFormStructure(data)
    : getEditGoalFormStructure(content, data);
};

export const getCreateFormStructure = (
  dataType: ToolBoxDataType,
  content
): FormStructure => {
  return dataType === ToolBoxDataType.MEDICATIONS
    ? getCreateMedicationFormStructure(content)
    : getCreateGoalFormStructure(content);
};

export const convertDateToFormattedDateString = (date: Date): string => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const convertDateToFormattedTimeString = (date: Date): string => {
  let hours = date.getHours();
  let minutes: any = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const sortDate = (data, descending = true, name = "date") => {
  return data.sort((d1, d2) => {
    return descending ? d2[name] - d1[name] : d1[name] - d2[name];
  });
};

export const groupBy = (arr, key) => {
  return (arr || []).reduce(
    (acc, x = {}) => ({
      ...acc,
      [x[key]]: [...(acc[x[key]] || []), x],
    }),
    {}
  );
};

export const unwrapResponse = (data) => {
  return data.map((item) => {
    const newItem = {};

    Object.keys(item).forEach((key) => {
      if (item[key][0] && item[key][0].value && item[key].length > 1) {
        newItem[key] = unwrapList(item[key]);
      } else if (item[key][0] && item[key][0].value) {
        newItem[key] = item[key][0].value;
      } else if (item[key][0]) {
        newItem[key] = item[key][0];
      } else {
        newItem[key] = item[key];
      }
    });

    return newItem;
  });
};

const unwrapList = (list) => {
  return list.map((item) => item.value);
};

export const unwrapObjectResponse = (data, omit = "") => {
  const newItem = {};

  Object.keys(data).forEach((key) => {
    const newKey = key.replace("field_", "");
    if (key === omit) {
      newItem[newKey] = data[key];
    } else if (newKey.includes("image") && data[key].length > 1) {
      newItem[newKey + "s"] = data[key];
    } else if (data[key].length > 1) {
      newItem[newKey] = [];
    } else if (data[key][0] && data[key][0].value !== undefined) {
      newItem[newKey] = data[key][0].value;
    } else if (data[key].length !== 0) {
      newItem[newKey] = data[key][0];
    } else {
      newItem[newKey] = undefined;
    }
  });

  return newItem;
};

export const replaceField = (data) => {
  const newItem = {};

  Object.keys(data).forEach((key) => {
    const newKey = key.replace("field_", "");
    newItem[newKey] = data[key];
  });

  return newItem;
};

const orderMainPageTiles = (galleryResp, arrays, data) => {
  if (!data["field_main_page"][0]["value"]) {
    return galleryResp;
  }

  let orderedGalleryResponse = [];

  arrays.forEach((elements) => {
    if (data[elements].length > 0) {
      const vals = Object.values(data[elements]);

      vals.forEach((element, idx) => {
        // @ts-ignore
        const target_uuid = element["target_uuid"];

        galleryResp.forEach((galleryElement) => {
          if (target_uuid === galleryElement["uuid"][0]["value"]) {
            // @ts-ignore
            orderedGalleryResponse[idx] = galleryElement;
          }
        });
      });
    }
  });

  if (orderedGalleryResponse !== []) return orderedGalleryResponse;

  return galleryResp;
};

export const getReferencedSectionData = (
  data,
  orderedData,
  key: string,
  dispatch,
  location
) => {
  const arrays = Object.keys(data).filter((cKey) => {
    return cKey.includes(key);
  });

  arrays.forEach((items) => {
    const target_ids = data[items].map((item) => item.target_id).join(",");

    if (target_ids !== "") {
      dispatch(loading(location + key));

      Api.getNode(target_ids).then((galleryResp) => {
        galleryResp = orderMainPageTiles(galleryResp, arrays, data);

        const unwrapped = unwrapResponse(galleryResp);

        const number = Number(items.match(/\d/)) - 1;

        getReferencedDataArray(galleryResp, "field_articulate_360").then(
          (resp) => {
            resp.forEach((module, i) =>
              unwrapped.forEach((item, index) => {
                if (item.field_articulate_360.target_id === module.nid) {
                  unwrapped[index].field_articulate_360 = module;
                }
              })
            );
            orderedData[number][key] = unwrapped;

            dispatch(updateSection(location, orderedData[number], number));
          }
        );

        orderedData[number][key] = unwrapped;

        dispatch(updateSection(location, orderedData[number], number));
        dispatch(finishedLoading(location + key, "", true));
      });
    }
  });
};

export const getReferencedData = (
  data,
  key: string,
  dispatch,
  location,
  name
) => {
  const arrays = Object.keys(data).filter((cKey) => {
    return cKey.includes(key);
  });

  arrays.forEach((items) => {
    const target_ids = data[items].map((item) => item.target_id).join(",");

    if (target_ids !== "") {
      dispatch(loading(location + key));
      Api.getNode(target_ids).then((galleryResp) => {
        const unwrapped = unwrapObjectResponse(galleryResp[0], "field_links");

        dispatch(updateContent(location, { [name]: unwrapped }));
        dispatch(finishedLoading(location + key, "", true));
      });
    }
  });
};

export const getReferencedMedia = (
  data: Object,
  key: string,
  dispatch,
  location,
  name
) => {
  const arrays = Object.keys(data).filter((cKey) => {
    return cKey.includes(key);
  });

  arrays.forEach((items) => {
    const target_ids = data[items].map((item) => item.target_id).join(",");

    if (target_ids !== "") {
      dispatch(loading(location + key));
      Api.getMedia(target_ids).then((galleryResp) => {
        const unwrapped = unwrapResponse(galleryResp);

        dispatch(updateContent(location, { [name]: unwrapped }));
        dispatch(finishedLoading(location + key, "", true));
      });
    }
  });
};

export const getReferencedMediaArray = (data: Array<any>, key: string) => {
  const targetIds = data
    .filter((item) => item[key])
    .map((item) => item[key].target_id)
    .join(",");

  if (targetIds === "") {
    return []
  }

  return Api.getMedia(targetIds).then((galleryResp) => {
    const unwrapped = unwrapResponse(galleryResp);
    return unwrapped;
  });
};

export const getReferencedDataArray = (data: Array<any>, key: string) => {
  const targetIds = data
    .filter((item) => item[key] && item[key].length !== 0)
    .map((item) => item[key][0].target_id)
    .join(",");

  if (targetIds === "") {
    return new Promise(() => {});
  }

  return Api.getNode(targetIds).then((galleryResp) => {
    const unwrapped = unwrapResponse(galleryResp);
    return unwrapped;
  });
};

export function getCoords(elem) {
  // crossbrowser version
  var box = elem.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

  var top = box.top + scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const sanitizeURL = (url) => {
  if (!url) return "";

  const safeUrl = url
    .replaceAll("%2f", "/")
    .replaceAll("%2F", "/")
    .replaceAll("%252f", "/")
    .replaceAll("%252F", "/")
    .replaceAll("&#039;", "'");

  return decodeURIComponent(decodeURIComponent(safeUrl));
};

export const noop = () => {};

export const getLoadingData = (loadingState, name) => {
  return {
    isLoading: loadingState.loading.includes(name),
    isSuccess: !!loadingState.success[name],
  };
};

export const wrapFormData = (data, keysWithTargetId) => {
  const modifiedData = {};

  Object.keys(data).forEach(function (key) {
    if (keysWithTargetId.includes(key)) {
      modifiedData[key] = { target_id: data[key] };
    } else {
      modifiedData[key] = [data[key]];
    }
  });

  return Object.assign({}, modifiedData, {
    title: [
      `Contact form entry ${new Date().toLocaleString().replace(",", "")}`,
    ],
  });
};

export const UTCToLocalTimeString = (d, format = "") => {
  const timeOffsetInHours = (new Date().getTimezoneOffset() / 60) * -1;
  const timeOffsetInMinutes = (timeOffsetInHours % 1) * 60;

  d.setHours(d.getHours() + timeOffsetInHours);
  d.setMinutes(d.getMinutes() + timeOffsetInMinutes);

  return d;
};

export const getWeekDays = () => {
  var baseDate = new Date(Date.UTC(2022, 0, 3)); // just a Monday
  var weekDays = new Array();
  for (let i = 0; i < 7; i++) {
    weekDays.push(baseDate.toLocaleDateString("ES", { weekday: "long" }));
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
};

export const createGAEvent = (event, category, label = "") => {
  (window as any).gtag("event", event, {
    event_category: category,
    event_label: label,
  });
};