import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "../../assets/scss/Toolbox/Toolbox.scss";
import Dialog from "../../component/Dialog";
import Footer from "../../component/Footer/Footer";
import PageContainer from "../../component/PageContainer/PageContainer";
import { Tabs } from "../../component/Tabs/Tabs";
import useDisclosure from "../../hooks/useDisclosure";
import { ToolBoxData, ToolBoxDataType } from "../../interfaces/ToolBoxData";
import { toolBoxState } from "../../store/Reducer";
import {
  convertDateToFormattedDateString,
  convertDateToFormattedTimeString,
  sortDate,
} from "../../util/util";
import GoalCreationForm from "./Goal/GoalCreationForm";
import GoalEditForm from "./Goal/GoalEditForm";
import MedicationCreationForm from "./Medication/MedicationCreationForm";
import MedicationEditForm from "./Medication/MedicationEditForm";
import ToolBoxListItem from "./ToolBoxListItem";

interface ToolBoxSettingsPageProps {
  dataType: ToolBoxDataType;
  editPageUrl: string;
  hasTabs?: boolean;
  groupData?: (data: any) => any;
  groupList?: (data: any) => any;
  name?: string;
  date?: string;
  startingDate?: string;
  showDate?: boolean;
  showTime?: boolean;
  showGroupName?: boolean;
  shortTitle?: boolean;
  noHashtag?: boolean;
  canAddCustom?: boolean;
  noHastag?: boolean;
  isMedication?: boolean;
}

export default function ToolBoxSettingsPage({
  dataType,
  editPageUrl,
  hasTabs = false,
  groupData = (data) => {
    return { 0: data };
  },
  groupList = (data) => {
    return { 0: data.sort((d1, d2) => parseInt(d2.id) - parseInt(d1.id)) };
  },
  name = "name",
  showGroupName = false,
  shortTitle = false,
  noHashtag = false,
  isMedication = false,
}: ToolBoxSettingsPageProps) {
  const toolbox: ToolBoxData = useSelector(toolBoxState);
  const [value, setValue] = useState(0);
  const [tabData, setTabData] = useState<Object>({});
  const [visibleData, setVisibleData] = useState(7);
  const [hasHiddenData, setHasHiddenData] = useState(false);
  const [selectedGoalId, setSelectedGoalId] = useState("");
  const [selectedMedicationId, setSelectedMedicationId] = useState("");
  const {
    isOpen: createIsOpen,
    onOpen: createOnOpen,
    onClose: createOnClose,
  } = useDisclosure();
  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose,
  } = useDisclosure();

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setVisibleData(visibleData + 7);
  };

  const { t } = useTranslation();

  const limitReached = isMedication && tabData?.[0]?.length >= 5

  useEffect(() => {
    let entityList: Array<any> = toolbox[dataType];

    if (hasTabs) {
      let data = groupData(entityList);

      let newData = [];
      if (value === 0) {
        newData = sortDate(data[0]);
      } else {
        newData = data[2] ? sortDate(data[2].concat(data[1])) : data[1];
      }

      setHasHiddenData(newData.length > visibleData);
      setTabData(groupList(newData.slice(0, visibleData)));
    } else {
      setTabData(groupList(entityList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, visibleData, toolbox]);

  const settingsPageLabels = [t("goalPages.active"), t("goalPages.completed")];

  return (
    <>
      <PageContainer
        dataType={dataType}
        cta={{
          onClick: createOnOpen,
          label: isMedication
            ? t("medicationPages.button.addNew.label")
            : t("goalPages.button.addNew.label"),
          limitReached: t("medicationPages.button.addNew.limitReached")
        }}
        buttonDisabled={limitReached}
      >
        <>
          <Box className={`toolbox-list`}>
            {hasTabs && (
              <Box className={"tab-wrapper"}>
                <Tabs
                  value={value}
                  onChange={(_, newValue) => handleChange(newValue)}
                  labels={settingsPageLabels}
                />
              </Box>
            )}
            {tabData &&
              Object.keys(tabData).map((group, i) => (
                <div key={i} className="tab-data-wrapper">
                  {showGroupName && (
                    <Box mt={{ sm: 9, lg: 10 }} className={"transparent"}>
                      <p className="group-name body-16-bold">
                        {convertDateToFormattedDateString(new Date(group))}
                      </p>
                    </Box>
                  )}
                  {isMedication && tabData[group] ? (
                    <div className="table-header-wrapper">
                      <div className="table-header">
                        {t("myProgress.leftSide.medicationSection.title")}
                      </div>
                      <div className="table-column-row">
                        {tabData[group].length > 0 && (
                          <>
                            <div className="row-name">
                              {t("medicationPages.table.medicationName")}
                            </div>
                            <div className="row-date">
                              {t("medicationPages.table.date")}
                            </div>
                            <div className="row-time">
                              {t("medicationPages.table.time")}
                            </div>
                          </>
                        )}
                        {tabData[group].length === 0 && (
                          <div className="row-empty">
                            {t("medicationPages.table.empty")}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!isMedication && tabData[group].length === 0 ? (
                    <div className="table-empty-wrapper">
                      <div className="table-empty">
                        <div className="row-empty">
                          {t("myProgress.leftSide.goalSection.empty")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="alternate-background odd">
                    {tabData[group].map((entity, i) => {
                      return (
                        <ToolBoxListItem
                          key={entity.id}
                          text={entity[name]}
                          number={i + 1}
                          linkTo={value === 0 ? editPageUrl + entity.id : ""}
                          onClick={() => {
                            isMedication
                              ? setSelectedMedicationId(entity.id)
                              : setSelectedGoalId(entity.id);
                            editOnOpen();
                          }}
                          showDate={isMedication ? true : false}
                          showTime={isMedication ? true : false}
                          date={convertDateToFormattedDateString(
                            new Date(entity.startingDate)
                          )}
                          time={convertDateToFormattedTimeString(
                            new Date(entity.startingDate)
                          )}
                          shortTitle={shortTitle}
                          noHashtag={noHashtag}
                          hasViewIcon={isMedication ? false : true}
                          isMedication={isMedication ? true : false}
                        />
                      );
                    })}
                  </div>
                </div>
              ))}
            {!tabData && "foo"}
            {hasHiddenData && (
              <Box
                mt={{ sm: 4, lg: 12 }}
                className="flex justify-center-mobile transparent"
              >
                <Button className="button-text" onClick={handleClick}>
                  {t("goalPages.seeMore")}
                </Button>
              </Box>
            )}
          </Box>
        </>
      </PageContainer>
      <Footer legal_number={"FK/BIO_PSP/032/23"} />

      <Dialog
        className="Dialog__Toolbox"
        open={createIsOpen}
        title={
          isMedication
            ? t("medicationPages.button.addNew.label")
            : t("goalPages.button.addNew.label")
        }
        contentText={isMedication ? "" : t("goalPages.dialog.description")}
        renderContent={
          isMedication ? (
            <MedicationCreationForm onClose={createOnClose} />
          ) : (
            <GoalCreationForm onClose={createOnClose} />
          )
        }
      />
      <Dialog
        className="Dialog__Toolbox"
        open={editIsOpen}
        title={
          isMedication
            ? t("medicationPages.button.edit.label")
            : t("goalPages.button.edit.label")
        }
        contentText={isMedication ? "" : t("goalPages.dialog.description")}
        renderContent={
          isMedication ? (
            <MedicationEditForm
              onClose={editOnClose}
              id={selectedMedicationId}
            />
          ) : (
            <GoalEditForm onClose={editOnClose} id={selectedGoalId} />
          )
        }
      />
    </>
  );
}
