interface SwitchProps {
  register?: () => void;
  field?: any;
  entity?: Object;
  onChange?: () => void;
  defaultChecked?: boolean;
  checked?: boolean | undefined;
  showValue?: boolean;
}

export const Switch = ({
  register = () => {},
  field = { name: "switch" },
  entity = {},
  onChange = () => {},
  defaultChecked = false,
  checked = undefined,
  showValue = false,
}: SwitchProps) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        ref={register}
        name={field.name}
        defaultChecked={entity[field.name] || defaultChecked}
        onChange={onChange}
        checked={checked}
      />
      <span className="slider round"></span>
      {showValue && (
        <span className={`value-label ${checked ? "on" : "off"}`}>
          {checked ? "Activar" : "Desactivar"}
        </span>
      )}
    </label>
  );
};
