/* eslint-disable @typescript-eslint/no-array-constructor */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import "../../assets/scss/Home/Home.scss";
import DynamicFooter from "../../component/Footer/DynamicFooter";
import { ContentPageContainer } from "../../component/PageContainer/ContentPageContainer";
import { HomePageType } from "../../interfaces/HomePageType";
import { CarouselItem, PageType } from "../../interfaces/Pages";
import { contentState } from "../../store/Reducer";
import { LowerHomePageSection } from "./LowerHomePageSection";

export const HomePage = () => {
  const content: HomePageType = useSelector(contentState)[PageType.home];

  const [heroContent, setHeroContent] = useState<CarouselItem>();

  const [inactiveCard, setInactiveCard] = useState<CarouselItem>();

  const [carouselCards, setCarouselCards] = useState(new Array());

  useEffect(() => {
    // @ts-ignore
    if (!content) {
      return;
    }

    if (!inactiveCard) {
      const carousel = [...content.sections[0].carousel_items];

      const inActive = carousel.splice(0, 1)[0];

      setCarouselCards(carousel);

      setHeroContent(inActive);

      setInactiveCard(inActive);
    }
  }, [content]);

  const uriFixed = heroContent?.field_hp_carousel_item_link?.uri?.replaceAll(
    "internal:/",
    ""
  );

  const fixImageUrl = (url?: string) => {
   if (!url) return url;
   //return url.replace('/files/', '/sites/default/files/');
   return url;
  };

  return (
    <ContentPageContainer className={"home"}>
      <section className="upper-section">
        <div className="hero">
          <div className={"hero-image-container"}>
            <picture className="hero-img">
              <source
                media="(min-width:768px)"
                srcSet={fixImageUrl(heroContent?.field_hero_image?.url)}
                type="image/webp"
              />
              <source
                media={"(min-width: 400px)"}
                srcSet={
                  fixImageUrl(heroContent?.field_hero_image_medium?.url) ||
                  fixImageUrl(heroContent?.field_hero_image?.url)
                }
                type={"image/png"}
              />
              <img
                src={
                  fixImageUrl(heroContent?.field_hero_image_small?.url) ||
                  fixImageUrl(heroContent?.field_hero_image?.url)
                }
                alt={heroContent?.field_hero_image.alt}
                height="100%"
                width="100%"
              />
            </picture>
          </div>
          <div className="hero-content">
            <div className="hero-content-container">
              <h1
                className={"site-title"}
                dangerouslySetInnerHTML={{ __html: heroContent?.title.replace('Kabicare', 'KabiCare') || "" }}>
              </h1>
              <p
                dangerouslySetInnerHTML={{ __html: heroContent?.body || "" }}
              ></p>
              {heroContent?.field_hp_carousel_item_link &&
                heroContent.field_hp_carousel_item_link.title && (
                  <p className="cta-link-wrapper">
                    <RouterLink
                      to={uriFixed}
                      dangerouslySetInnerHTML={{ __html: heroContent?.field_hp_carousel_item_link.title || "" }}>
                    </RouterLink>
                  </p>
                )}
            </div>
          </div>
          {inactiveCard && (
            <LowerHomePageSection
              setHeroContent={setHeroContent}
              carouselCards={carouselCards}
              setCarouselCards={setCarouselCards}
              inactiveCard={inactiveCard}
              setInactiveCard={setInactiveCard}
            />
          )}
          <div className="left-side-linear-blur"></div>
        </div>
      </section>
      <DynamicFooter {...content?.footer_dynamic} />
    </ContentPageContainer>
  );
};
